exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-cadastro-de-novo-usuario-js": () => import("./../../../src/pages/cadastro-de-novo-usuario.js" /* webpackChunkName: "component---src-pages-cadastro-de-novo-usuario-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-planos-js": () => import("./../../../src/pages/planos.js" /* webpackChunkName: "component---src-pages-planos-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-projetos-js": () => import("./../../../src/pages/projetos.js" /* webpackChunkName: "component---src-pages-projetos-js" */),
  "component---src-pages-recuperar-senha-js": () => import("./../../../src/pages/recuperar-senha.js" /* webpackChunkName: "component---src-pages-recuperar-senha-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-solicitar-orcamentos-js": () => import("./../../../src/pages/solicitar-orcamentos.js" /* webpackChunkName: "component---src-pages-solicitar-orcamentos-js" */),
  "component---src-pages-solucoes-js": () => import("./../../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-suporte-js": () => import("./../../../src/pages/suporte.js" /* webpackChunkName: "component---src-pages-suporte-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

